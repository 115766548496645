<template>
	<div class="returnGoods_box">
		<Title name="首页   >   我的订单   >   退货/售后"></Title>
		<div class="content_box">
			<div class="goodsList flex">
				<div class="goodsImg">
					<img :src="goodsData.goodsUrl" alt="" />
				</div>
				<div class="goodsInfo">
					<div class="goodsName row">{{ goodsData.goodsName }}</div>
					<div class="goodsGg">{{ goodsData.skuTitle }}</div>
					<div class="goodsPrice">
						￥{{ Number(goodsData.unitPrice).toFixed(2) }}
					</div>
				</div>
			</div>
			<div class="selectType_box">
				<div class="selectType">选择服务类型</div>
				<div class="selectType_list flex flex-wrap">
					<div class="list flex pointer" @click="refundBtn(3)">
						<img src="./img/tq.png" alt="" />
						<div class="t_box">
							<div class="typeName">我要退款（无需退货）</div>
							<div class="details">
								没收到货，或与卖家协商同意不用退货只退款
							</div>
						</div>
					</div>
					<div class="list lists flex pointer" @click="refundBtn(1)">
						<img src="./img/th.png" alt="" />
						<div class="t_box">
							<div class="typeName">我要退货退款</div>
							<div class="details">已收到货，需要退还收到的货物</div>
						</div>
					</div>
					<div class="list flex pointer" @click="refundBtn(2)">
						<img src="./img/t.png" alt="" />
						<div class="t_box">
							<div class="typeName">我要换货</div>
							<div class="details">商品存在质量问题,联系卖家协商换货</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Title from './components/title.vue'
export default {
	components: {
		Title,
	},
	data() {
		return {
			goodsData: {},
		}
	},
	mounted() {
		this.getGoodsData()
	},
	methods: {
		//获取商品信息
		getGoodsData() {
			let params = {
				orderItemId: this.$route.query.orderId,
				token: this.$store.state.userData.token,
			}
			this.api.findUserOrderItemDetails(params).then((res) => {
				this.goodsData = res.data
			})
		},
		refundBtn(type) {
			this.$router.push({
				path: '/userInfo/returnGoodsDetails',
				query: {
					orderId: this.goodsData.id,
					type: type,
				},
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.returnGoods_box {
	width: 1200px;
	margin: 0 auto;
	.content_box {
		width: 100%;
		background: white;
		.goodsList {
			width: 100%;
			height: 128px;
			padding: 25px 24px 23px;
			box-sizing: border-box;
			border-bottom: 1px solid #eaeaea;
			.goodsImg {
				width: 80px;
				img {
					width: 80px;
					height: 80px;
				}
			}
			.goodsInfo {
				margin-left: 15px;
				.goodsName {
					width: 558px;
					font-size: 14px;
					color: #333333;
					margin-top: 5px;
					line-height: 13px;
				}
				.goodsGg {
					font-size: 14px;
					color: #888888;
					margin-top: 12px;
				}
				.goodsPrice {
					font-size: 18px;
					color: #e62129;
					margin-top: 12px;
				}
			}
		}
		.selectType_box {
			width: 100%;
			padding: 29px 26px;
			box-sizing: border-box;
			margin-bottom: 28px;
			.selectType {
				font-size: 18px;
				color: #333333;
			}
			.selectType_list {
				margin-top: 27px;
				.lists {
					margin-right: 0px !important;
				}
				.list {
					width: 548px;
					height: 138px;
					background: #ffffff;
					border: 1px solid #d0d0d0;
					border-radius: 5px;
					padding-top: 33px;
					padding-left: 65px;
					box-sizing: border-box;
					margin-right: 36px;
					margin-bottom: 30px;
					img {
						width: 63px;
						height: 63px;
					}
					.t_box {
						margin-left: 48px;
						.typeName {
							font-size: 20px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #333333;
							line-height: 20px;
							margin-top: 8px;
						}
						.details {
							font-size: 16px;
							color: #666666;
							line-height: 20px;
							margin-top: 14px;
						}
					}
				}
			}
		}
	}
}
</style>