<template>
    <div class="title_box">
        <div class="title" @click="goBtn">{{name}}</div>
    </div>
</template>
<script>
export default {
    props:{
        name:{}
    },
    data(){
        return{}
    },
    methods:{
        goBtn(){
            this.$router.go(-1)
        }
    }
}
</script>
<style lang="scss" scoped>
    .title_box{
        .title{
            font-size: 12px;
            color: #666666;
            margin-top: 20px;
            margin-bottom: 23px;
        }
    }
</style>